<template>
    <div>

        <!-- Valoración de escalas -->
        
        <v-toolbar short dense color="primary" dark flat>
            <v-toolbar-title>Escalas</v-toolbar-title>
            <v-spacer/>
            

            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn icon @click="cargaEscalasTodas" v-on="on">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </template>
                <span>Recargar</span>
            </v-tooltip>

            <v-btn
                v-if="muestraEscalas"
                icon
                color="white"
                dark
                @click="muestraEscalas = false"
            >
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>


        </v-toolbar>

        <v-row no-gutters >

            
            <v-col 
                v-for="itm in escalasTodas"
                :key="itm.id"

                xs="12"
                sm="6"
                md="4"
                xl="3"
                cols="12"
            >
                <enfermeria-escala
                    class="ma-1"
                    :nombre="itm.nombre"
                    :valora="itm.escalaId"
                    :datos="datosEscalas[itm.escalaId]"
                    @cargaValora="cargaValora"
                    @borrado="cargaEscalasTodas"
                />
            </v-col>
           
        </v-row>


        <!-- Valoracion por aparatos y sistemas -->
        <v-toolbar short dense color="primary" dark flat class="mt-1">
            <v-toolbar-title>Valoraciones</v-toolbar-title>
        </v-toolbar>

        <v-row no-gutters>
            <v-col
                v-for="itm in datosTurnos" :key="itm.val"
                xs="12"
                sm="12"
                md="4"
                cols="12"
            >
                <valoraciones-detalles
                    class="ma-1" 
                    :turno="itm.val"
                    :estaFecha="estaFecha"
                    :internamiento="internamiento"
                />
            </v-col>
        </v-row>

        <!-- ------------------------------------------------------
        Los dialog para agregar las escalas
        ---------------------------------------------------------- -->
            <component
                v-for="itm in escalasTodas"
                :key="itm.id"
                :is="itm.componente"

                :nombre="itm.nombre"
                :dialogDe.sync="dialogsEscalas[itm.escalaId]"
                :turno="turnosEscalas"
                :estaFecha="estaFecha"
                :internamiento="internamiento"
                @escalaSuccess="cargaEscalasTodas"
            />
    </div>
</template>

<script>
export default {
    created (){
        
        this.initEscalas()
        this.cargaEscalasTodas()
    },
    components:{
        'agrega-valgral':()=> import('@/components/hospital/enfe/valoraciones/EscalaEstadoGral'),
        'enfermeria-escala':()=> import('@/components/hospital/enfe/valoraciones/Escalas'),
        'agrega-reactividad':()=> import('@/components/hospital/enfe/valoraciones/EscalaReactividad'),
        'agrega-conciencia':()=> import('@/components/hospital/enfe/valoraciones/EscalaConciencia'),
        'agrega-caidas':()=> import('@/components/hospital/enfe/valoraciones/EscalaCaidas'),
        'agrega-upp':()=> import('@/components/hospital/enfe/valoraciones/EscalaUpp'),
        'agrega-dolor':()=> import('@/components/hospital/enfe/valoraciones/EscalaDolor'),
        'agrega-dolor-pipp':()=> import('@/components/hospital/enfe/valoraciones/EscalaDolorPipp'),
        'agrega-flebitis':()=> import('@/components/hospital/enfe/valoraciones/EscalaFlebitis'),
        'agrega-ramsay':()=> import('@/components/hospital/enfe/valoraciones/EscalaRamsay'),
        'agrega-npass':()=> import('@/components/hospital/enfe/valoraciones/EscalaNpass'),
        'agrega-silverman':()=> import('@/components/hospital/enfe/valoraciones/EscalaSilverman'),

        'valoraciones-detalles':()=> import('@/components/hospital/enfe/valoraciones/ValoracionesDetalles'),
    },
    props:{
        estaFecha: String,
        internamiento: Number,
    },
    data:()=>({
        
        turnosEscalas:'',
        tabEscala:0,

        dialogsEscalas:{},

        datosEscalas:{},

        escalasTodas:[
            {id:0, nombre:'Valoración general', escalaId:'general', componente:'agrega-valgral'},
            {id:1, nombre:'Reactividad Pupilar', escalaId:'reactividad', componente:'agrega-reactividad'},
            {id:2, nombre:'Estado de conciencia (Glasgow)', escalaId:'conciencia', componente:'agrega-conciencia'},
            {id:3, nombre:'Sedación (Ramsay)', escalaId:'ramsay', componente:'agrega-ramsay'},
            {id:4, nombre:'Riesgo de caidas', escalaId:'caidas', componente:'agrega-caidas'},
            {id:5, nombre:'Úlceras por presión', escalaId:'upp', componente:'agrega-upp'},
            {id:6, nombre:'Dolor (EVA)', escalaId:'dolor', componente:'agrega-dolor'},
            {id:7, nombre:'Dolor (PIPP)', escalaId:'dolorPipp', componente:'agrega-dolor-pipp'},
            {id:8, nombre:'Dolor (N-PASS)', escalaId:'npass', componente:'agrega-npass'},
            {id:9, nombre:'Flebitis', escalaId:'flebitis', componente:'agrega-flebitis'},
            {id:10, nombre:'Silverman', escalaId:'silverman', componente:'agrega-silverman'},
        ],
        muestraEscalas:false,
        muestraValoraciones:false,
        datosTurnos:[
            {val:'mat', text: 'Matutino', color:'blue'},
            {val:'ves', text: 'Vespertino', color:'green'},
            {val:'noc', text: 'Nocturno', color:'red'},
        ],
    }),
    methods:{

        initEscalas(){
            this.dialogsEscalas={
                general:false,
                reactividad:false,
                conciencia:false,
                ramsay:false,
                caidas:false,
                upp:false,
                dolor:false,
                dolorPipp:false,
                npass:false,
                flebitis:false,
                silverman:false,
            }

            this.datosEscalas={
                general:[],
                reactividad:[],
                conciencia:[],
                ramsay:[],
                caidas:[],
                upp:[],
                dolor:[],
                dolorPipp:[],
                flebitis:[],
                npass:[],
                silverman:[],
            }
        },

        async cargaEscalasTodas(){

            this.initEscalas()

            this.loadingDatosEscalas = true
            
            let me = this
            let params = {
                internamiento: this.internamiento,
                fecha: this.estaFecha
            }
            try {
                // Obtiene todos los datos (datos crudos)
                let escalas = await this.$http({
                    url:'/enfermeria/cargaEscalas',
                    method:'GET',
                    params: params,
                })
                
                //console.log(escalas)

                if (escalas.data.estado == true){
                    let arrayEstados = []
                    // -----------------
                    // Filtra los obtenidos para cada escala
                    escalas.data.root.forEach(dt => {
                        arrayEstados.push(dt.escala)
                    });
                    // Este es el que filtra
                    arrayEstados = Array.from(new Set(arrayEstados))
                    
                    // Busca en en los datos crudos por los que correspondan para agregarlos
                    
                    arrayEstados.forEach(dt =>{
                        
                        // Limpia el array para obtener nuevos datos
                        me.datosEscalas[dt] = []

                        escalas.data.root.forEach(dt2 =>{
                            //datosEscalas
                            if (dt == dt2.escala)
                            me.datosEscalas[dt].push(dt2)
                            //console.log(dt2)
                        })
                    })
                    this.loadingDatosEscalas = false
                    //console.log(me.datosEscalas)
                }
            } catch (err){
                //console.log(err)
                this.loadingDatosEscalas = false
                this.$store.dispatch('security/revisaError',err)
            }
        },

        cargaValora(valora, turno){
            this.dialogsEscalas[valora]=true
            this.turnosEscalas=turno
        },
        
    },
    watch:{
        estaFecha(){
                //Carga la acción para cargar estados
                this.cargaEscalasTodas()
            },
    }

}
</script>

<style>

</style>